// Libs
import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// images
import MapImage from 'images/mapsImages/belavista-detalhes.png';

// Components
import SEO from 'components/seo';
import { device } from 'components/device';
import Layout from 'components/layout';

import AddressTimes from 'components/units/unitPages/unit/addressTimes';
import FeaturedMarketing from 'components/units/unitPages/unit/detailsFeaturedMarketing';
// import PublicTransportation from 'components/unitPages/details/publicTransportation'
import SocialShare from 'components/units/unitPages/details/socialShare';
import TextSection from 'components/units/unitPages/details/TextSection';
import DetailsTitle from 'components/units/unitPages/details/DetailsTitle';
import BreadCrumb from 'components/breadCrumb';
import Certificate from 'components/ASCOCertification';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};
  @media ${device.mobile} {
    padding: 0 20px 20px;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderFeaturedMarketing = page => (
  <FeaturedMarketing
    WithoutItemBg
    singleText={getMarkdown(page, 'ckmuwmy5k1xc90b326j029ptp')}
    servicesList={getMarkdown(page, 'ckmuwmy5k1xc90b326j029ptp', true)}
    asset={getMarkdown(page, 'ckmuwmy5k1xc90b326j029ptp', false, true)}
  />
);

const renderPage = (page, isDesktop, location) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'}>
      <BreadCrumb
        details
        markdown={getMarkdown(page, 'ckmxrov9477300b641dm33h4w', true)}
      />
      <DetailsTitle
        titleWidth="34rem"
        titleWidthMobile="16rem"
        markdown={getMarkdown(page, 'ckmxk2a80j8fi0b32y4sdofio', true)}
      />
    </Part>
    {!isDesktop && renderFeaturedMarketing(page)}
    <Container>
      <Part gridColumn={'1 / span 6'}>
        {isDesktop && renderFeaturedMarketing(page)}
        {/* About */}
        <TextSection
          isAbout
          isAboutMobile
          gridArea="ABT"
          isShowing
          markdown={getMarkdown(page, 'ckr2j65ew3c8a0b813bfvbh9f', true)}
        />
        {/* Structure */}
        <TextSection
          markdown={getMarkdown(page, 'ckr2jbp9c3f030b81li5jbi1g', true)}
          gridArea="STRUC"
          isAboutMobile
          doNotApplyDisplayBlock
          isShowing
        />
        {/* Others Units Mobile */}
        <TextSection
          markdown={getMarkdown(page, 'ckmuxg3jc28h80c669112p7m7', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
        />
      </Part>
      <Part gridColumn={'8 / span 5'}>
        <AddressTimes
          isShowingAttention
          markdown={getMarkdown(page, 'ckmux40co23ts0b32kpg3qwvw', true)}
          fragments={getMarkdown(page, 'ckmux40co23ts0b32kpg3qwvw')}
          location={{ lat: -23.557561, lng: -46.654542 }}
          zoom={16}
          isDetails
          isWhatsapp
          mapPreview={MapImage}
          isStatic
          isDesktop={isDesktop}
        />
        <TextSection
          markdown={getMarkdown(page, 'ckmux9u8826j50c80l2cvvfmj', true)}
          gridArea="PAR"
          isShort
          isAboutMobile
          isShowing
        />
        {/* Others Units */}
        <TextSection
          markdown={getMarkdown(page, 'ckmuxg3jc28h80c669112p7m7', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
          isShowing
          isOthersUnitsMobile
        />
        {isDesktop && (
          <SocialShare
            location={location}
            title="Unidade Oncologia Bela Vista"
          />
        )}
      </Part>
    </Container>
    {!isDesktop && (
      <SocialShare location={location} title="Unidade Oncologia Bela Vista" />
    )}
    <Part gridColumn={'2 / -2'}>
      <Certificate isOncologia />
    </Part>
  </GridContainer>
);

const OncologiaBelaVista = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckmuwmy5k1xc90b326j029ptp', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, location)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckmuwj8o01vwq0c8052g0pw2q" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <OncologiaBelaVista
            page={response.gcms.site.pages}
            location={location}
          />
        );
      }}
    />
  );
};
